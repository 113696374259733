import { CoreEnv } from '@eventbrite/context-gen';
import { openInNewTab, setWindowLocation } from '@eventbrite/http';

export const openEventUrl = (url: string, ticketsBy = 'Eventbrite') => {
    if (ticketsBy === 'Eventbrite') {
        setWindowLocation(url);
    } else {
        openInNewTab(url);
    }
};

export const isProd = ({ env }: { env: CoreEnv }) => {
    return env.ebDomain.indexOf('eventbrite') > -1;
};

export const getSubApp = (pathname: string) => {
    if (pathname.includes('/b/')) {
        return 'category-browse';
    }

    if (pathname.includes('/d/') && pathname.includes('/events/')) {
        return 'browse';
    }

    if (pathname.includes('/d/')) {
        return 'search';
    }

    return 'home';
};
