/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable import/first */
// Entry point for the feed.web.js bundle, this is the first JS from our app that runs
import { datadogRum } from '@datadog/browser-rum-slim';
import { CoreApplicationContext } from '@eventbrite/context-gen';
import { getSubApp, ServerProps } from '@eventbrite/discover-lib';
import { EBEnvs, getCurrentEnv } from '@eventbrite/feature-detection';
import { initializeRealUserMonitoring } from '@eventbrite/real-user-monitoring';
import {
    Dedupe as DedupeIntegration,
    ExtraErrorData as ExtraErrorDataIntegration,
} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import './EdsOverrides.scss';
import './index.scss';

const props: ServerProps = window.__SERVER_DATA__ || {};
const currentEnv = getCurrentEnv(props);

const SAMPLE_RATE_MAP = {
    home: 2,
    browse: 7.5,
    search: 0.75,
    'category-browse': 15,
};

const shouldUseSentry = () => {
    return (
        currentEnv === EBEnvs.PROD ||
        currentEnv === EBEnvs.QA ||
        currentEnv === EBEnvs.STAGE
    );
};

const shouldUseDatadog = () => {
    return currentEnv === EBEnvs.PROD || currentEnv === EBEnvs.QA;
};

const setSentryCustomTags = () => {
    if (
        (
            window.__SERVER_DATA__ as CoreApplicationContext & {
                guest_id: string;
            }
        )?.guest_id
    ) {
        Sentry.setTag(
            'guest_id',
            (
                window.__SERVER_DATA__ as CoreApplicationContext & {
                    guest_id: string;
                }
            ).guest_id,
        );
    }

    Sentry.setTag(
        'page_locale',
        (window as Window & { __i18n__?: { locale: string } }).__i18n__
            ?.locale || '',
    );

    if (window.location && window.location.pathname) {
        const subApp = getSubApp(window.location.pathname);
        Sentry.setTag('sub_app', subApp);
    }
};

const checkForSSRError = () => {
    const env = currentEnv;

    //Server rendering doesn't work on dev environments so only throw errors
    //in places that we can actually verify.
    const shouldValidateSSREnvironment =
        env === EBEnvs.PROD || env === EBEnvs.QA;

    if (typeof document !== 'undefined' && shouldValidateSSREnvironment) {
        const rootElement = document.getElementById('root');
        if (rootElement?.childElementCount === 0) {
            Sentry.captureException(
                Error(
                    'Root element has 0 pre-rendered children. Server Rendering may be broken.',
                ),
            );
        }
    }
};

setTimeout(() => {
    if (shouldUseSentry()) {
        Sentry.init({
            dsn: 'https://9a651a886d0d43a79d58cdcefa083e97@o375540.ingest.sentry.io/5474383',
            integrations: [
                new DedupeIntegration(),
                new ExtraErrorDataIntegration(),
            ],
            ignoreErrors: [
                'Non-Error exception captured',
                'Non-Error promise rejection captured',
                'branch-latest',
                'pagead/viewthroughconversion',
                'SIE',
                'hubspot',
                'siteintercept.qualtrics.com',
                'TypeError: Load failed',
                'ChunkLoadError',
                'Loading CSS chunk',
            ],
            release: window.__SERVER_DATA__?.app_version,
            allowUrls: [
                /^(https:\/\/)?(www\.)?(eventbrite|evbqa|evbdev).+/,
                /^(https:\/\/)?(cdn\.)(evbstatic).+/,
            ],
            environment: currentEnv || '',
        });

        if (window.__SERVER_DATA__?.user.email) {
            const sentryUser = {
                email: window.__SERVER_DATA__.user.email,
                id: window.__SERVER_DATA__.user.publicId,
            };

            // MUST have one of email, id, username, or ip to call setUser()
            Sentry.setUser(sentryUser);
        }

        setSentryCustomTags();
        checkForSSRError();
    }
}, 0);

setTimeout(() => {
    if (shouldUseDatadog() && window.location && window.location.pathname) {
        const subApp = getSubApp(window.location.pathname);
        const sampleRate =
            currentEnv == EBEnvs.PROD ? SAMPLE_RATE_MAP[subApp] : 100;

        initializeRealUserMonitoring({
            sessionSampleRate: sampleRate,
            version: props.app_version,
            service: props.app_name,
            env: currentEnv || undefined,
            enableExperimentalFeatures: ['feature_flags'],
        });

        datadogRum.onReady(() => {
            datadogRum.setGlobalContextProperty('view.sub_app', subApp);

            if (props?.user?.email) {
                const datadogRumUser = {
                    email: props.user.email,
                    id: props.user.publicId,
                };

                datadogRum.setUser(datadogRumUser);
            }
        });
    }
}, 0);

// Sometimes dynamic imports fail on slow connections
// For now just silence the errors to stop sending (there are too many)
// eslint-disable-next-line func-style
function genericDynamicImportErrorHandler(err: any) {
    if (currentEnv !== EBEnvs.PROD) {
        throw err;
    }

    return null;
}

import('./index.post').catch(genericDynamicImportErrorHandler);
