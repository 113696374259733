import { EBDomains, EBEnvs } from './types';

export const DOMAINS_ENVS = {
    [EBDomains.EVENTBRITE]: EBEnvs.PROD,
    [EBDomains.EVBSTAGE]: EBEnvs.STAGE,
    [EBDomains.EVBQA]: EBEnvs.QA,
    [EBDomains.EVBDEV]: EBEnvs.DEV,
};

export const DOMAINS: EBDomains[] = [
    EBDomains.EVENTBRITE,
    EBDomains.EVBSTAGE,
    EBDomains.EVBQA,
    EBDomains.EVBDEV,
];
