import { CoreApplicationContext } from '@eventbrite/context-gen';
import { DOMAINS_ENVS } from './constants';
import { getDomain } from './helpers';
import { EBEnvs } from './types';

export const getCurrentEnv = (
    context?: CoreApplicationContext | Pick<CoreApplicationContext, 'env'>,
): EBEnvs | null => {
    const env = context?.env;
    const keyWord = getDomain(env);
    return keyWord ? DOMAINS_ENVS[keyWord] : null;
};
