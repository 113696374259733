import { HAS_WINDOW } from './has-window';

/**
 * Safely gets a property from the window object
 * @param {string} key property on window to retrieve
 */
export function getWindowObject<Key extends keyof Window>(
    key: Key,
): Window[Key] | null {
    return HAS_WINDOW ? window[key] : null;
}
