export enum EBEnvs {
    PROD = 'prod',
    STAGE = 'stage',
    QA = 'qa',
    DEV = 'dev',
}

export enum EBDomains {
    EVENTBRITE = 'eventbrite',
    EVBSTAGE = 'evbstage',
    EVBQA = 'evbqa',
    EVBDEV = 'evbdev',
}
